import React from 'react';
import { NavLink } from "react-router-dom";
import { Menu, MenuItem, ListItem, ListItemIcon, ListItemText, Divider, Paper, Button, Stack, Chip } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import User from '../../types/User';
import Team from '../../types/Team';
import Avatar from './Avatar';

type UserMenuProps = {
  user: User,
  team: Team,
  anchorEl: Element | (() => Element),
  open: boolean,
  onClose: () => void
}

const UserMenu = (props: UserMenuProps) => {
  return(
    <Menu
      id="user-menu"
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.onClose}
    >
      <ListItem>
        <ListItemText>
          <Stack sx={{alignItems: "center"}} spacing={1}>
            <Avatar size={60} />
            <h3><small>{props.user.display_name}</small></h3>
            <small style={{margin: 0}} className="text-secondary">{props.user.email}</small>
            <Chip color="primary" icon={<Diversity3Icon fontSize='small' />} label={<strong>{props.team.name}</strong>} />
          </Stack>
        </ListItemText>
      </ListItem>

      <Divider />
      
      <MenuItem component={NavLink} to="/user" onClick={props.onClose}>
        <ListItemIcon><SettingsOutlinedIcon fontSize="small" /></ListItemIcon>
        <ListItemText>Account Einstellungen</ListItemText>
      </MenuItem>

      <Divider />

      { props.team.admin && <>
      <MenuItem component={NavLink} to="/team" onClick={props.onClose}>
        <ListItemIcon><Diversity3Icon fontSize="small" /></ListItemIcon>
        <ListItemText>Team Einstellungen</ListItemText>
      </MenuItem>

      <Divider /> 
      </> }

      <MenuItem component={NavLink} to="/teams" onClick={props.onClose}>
        <ListItemIcon><SyncIcon fontSize="small" /></ListItemIcon>
        <ListItemText>Team wechseln</ListItemText>
      </MenuItem>
      <MenuItem component="a" href="/logout">
        <ListItemIcon><LogoutOutlinedIcon fontSize="small" /></ListItemIcon>
        <ListItemText>Abmelden</ListItemText>
      </MenuItem>
    </Menu>
  );
}
export default UserMenu;