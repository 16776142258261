import React, { useState, useEffect } from "react";
import { Paper, Button, TextField, FormGroup, Stack, Autocomplete, Chip, CircularProgress } from '@mui/material';
import FormUtils from "../../utils/FormUtils";
import Api from "../../network/Api";
import Toolbox from "../Shared/Toolbox";
import PostAddIcon from '@mui/icons-material/PostAdd';

const NewLesson = () => {

  const [showsToolbox, showToolbox] = useState(false);
  const [isLoading, shouldLoad] = useState(false);
  const [proposals, setProposals] = useState();
  const [labels, setLabels] = useState();
  const [lesson, setLesson] = useState();

  useEffect(() => {
    if (!isLoading) { return }
    Api.post(`/labels`, { lesson: lesson }).then(result => {
      setProposals(result.labels);
      setLabels(result.labels);
      shouldLoad(false);
    })
  }, [isLoading]);

  useEffect(() => {
    if (lesson && lesson.length > 10) {
      shouldLoad(true)
    }
  }, [lesson]);

  return <>
    <Stack direction="row" sx={{alignItems: 'center', gap: 2}}>
      <Button variant="outlined" 
              color="secondary"
              size="large"
              startIcon={<PostAddIcon />}
              onClick={() => showToolbox(true)}>Add Lessons Learned</Button>
    </Stack>

    <Toolbox title="Add new lessons learned"
             open={showsToolbox} 
             onClose={() => showToolbox(false)}>
      <form method="post" action="/lessons" onSubmit={(e) => { FormUtils.onFormSubmit(e); }}>
        <FormGroup>
          <Paper className="bg-grey" elevation={0}>

            <FormGroup>
              <TextField title="Lesson ..." 
                          name="lesson" 
                          multiline
                          value={lesson}
                          onChange={e => setLesson(e.target.value)} />
            </FormGroup>
            
            { proposals && <FormGroup>
              <Autocomplete
                multiple
                options={[]}
                defaultValue={proposals}
                freeSolo
                disableClearable
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="filled" color="primary" label={option} {...getTagProps({ index })} />
                  ))
                }
                onChange={(e, value) => setLabels(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Themen"
                    placeholder="Themen"
                  />
                )}
              />
              <input type="hidden" name="labels" value={labels} />
            </FormGroup> }
            
          </Paper>
        </FormGroup>

        <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'flex-end', gap: 2}}>
          { isLoading && <CircularProgress size={20} color='secondary' /> }
          <Button variant="outlined" type="submit" disabled={!labels || labels.length <= 0}>Hinzufügen</Button>
        </Stack>
      </form>
    </Toolbox>
  </>;
}

export default NewLesson;