import React, { useState, useEffect } from "react";
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";

const ConsentScripts = (props) => {

  const [allowed, setAllowed] = useState(getCookieConsentValue());

  useEffect(() => {
    
    if (allowed !== 'true') { return }

    window.hsConversationsSettings = {
      loadImmediately: false,
    }
    addScript("https://www.googletagmanager.com/gtag/js?id=G-P6G03H5W1T");
    
    gtag('js', new Date());
    gtag('config', 'G-P6G03H5W1T');
  });

  const addScript = (url) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  return (<CookieConsent
            location="top"
            buttonText="Zustimmen"
            ariaAcceptLabel="Zustimmen"
            enableDeclineButton={true}
            declineButtonText="Ablehnen"
            ariaDeclineLabel="Ablehnen"
            style={{ background: 'var(--background-dark)' }}
            buttonStyle={{ color: 'white', background: 'var(--color-primary)' }}
            declineButtonStyle={{ color: 'white', background: "transparent" }}
            onAccept={() => setAllowed('true')}
            onDecline={() => setAllowed('false')}
          >
            Wir setzen zum Erhalt der Funktionalität der Anwendung Cookies und ähnliche Technologien ein. Du willigst den Einsatz solcher Technologien ein, indem du auf Zustimmen klickst, indem du irgendeinen Link oder Button außerhalb dieses Hinweises betätigst oder indem du die Nutzung anderweitig fortsetzt.
          </CookieConsent>
  );
}

export default ConsentScripts;