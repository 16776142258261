import React, { useState } from "react";
import { Container, Grid, Button, AppBar, IconButton, Toolbar, Collapse, MenuList, MenuItem, Card, FormGroup, Stack } from '@mui/material';
import { NavLink } from "react-router-dom";
import Logo from "../Shared/Logo";
import ConsentScripts from "../../utils/ConsentScripts";
import MenuIcon from '@mui/icons-material/Menu';
import GppGoodIcon from '@mui/icons-material/GppGood';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import RocketLaunchTwoToneIcon from '@mui/icons-material/RocketLaunchTwoTone';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PricingBoxes from "../Shared/PricingBoxes";

const Landingpage = () => {

  const [menuShown, showMenu] = useState(false);

  const MenuItems = (props) => {
    return <MenuList sx={{display: props.responsive ? { xs: 'none', md: 'flex' } : 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: { sx: '1rem', md: '3rem' }, flexGrow: 1}}>
      <MenuItem component={NavLink} disableRipple>Features</MenuItem>
      <MenuItem component={NavLink} disableRipple>Use Cases</MenuItem>
      <MenuItem component={NavLink} disableRipple>Pricing</MenuItem>
      <MenuItem component={NavLink} disableRipple>FAQ</MenuItem>

      <MenuItem component={NavLink} to="/login" className="MuiButton-root MuiButton-outlined MuiButton-outlinedSecondary MuiButton-sizeSmall" sx={{marginLeft: "auto", display: { xs: 'none', md: 'flex'}}}>Sign In</MenuItem>
      <MenuItem component={NavLink} to="/login" sx={{ display: { md: 'none' } }}>Sign In</MenuItem>
    </MenuList>
  }

  return (<div id="landingpage" className="bg-white">

    <ConsentScripts />

    <AppBar component="nav" position="relative">
      <Toolbar sx={{gap: '3rem', minHeight: "80px !important"}}>
        <Logo color="primary" />
        <IconButton 
          sx={{marginLeft: 'auto', display: { md: 'none' }}}
          onClick={() => showMenu(shown => !shown)}>
            <MenuIcon />
        </IconButton>
        <MenuItems responsive={true} />
      </Toolbar>
    </AppBar>

    <Collapse in={menuShown} className='bg-white' sx={{ flexGrow: 1, display: { md: 'none' } }}>
      <MenuItems />
    </Collapse>

    <header style={{padding: "5rem 0 3rem"}}>
      <Container maxWidth="lg text-center">
        <h2 className="text-secondary">Transforming past mistakes into future success</h2>
        <h1>AI driven lessons learned copilot</h1>
        <Button variant="contained" color="primary" size="large" component={NavLink} to="/login" sx={{mt: '1rem'}} endIcon={<ArrowRightAltIcon />}>Try it out</Button>
      </Container>
    </header>

    <section id="features">
      <Container maxWidth="lg" className="text-center">

        <Grid container className="text-primary">
          <Grid item xs={12} md={4} sx={{padding: '1rem'}}>
            <Card className="bg-grey" elevation={0}>
              <AssuredWorkloadIcon fontSize="large" color="warning" />
              <h3>GDPR &amp; AI Act Compliant</h3>
              <p>Securely hosted in the EU</p>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{padding: '1rem'}}>
            <Card className="bg-grey" elevation={0}>
              <GppGoodIcon fontSize="large" color="info" />
              <h3>Data Secure</h3>
              <p>Encrypted service</p>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{padding: '1rem'}}>
            <Card className="bg-grey" elevation={0}>
              <RocketLaunchTwoToneIcon fontSize="large" color="error" />
              <h3>Integrated</h3>
              <p>Connect with your PM tool</p>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </section>

    {/* <section id="bullets" className="text-center">
      <h2>So funktioniert's</h2>
      <p className="text-lg">Beschreibung...</p>
    </section> */}

    <section id="pricing" className="bg-dark">
      <Container maxWidth="lg">
        <FormGroup className="text-center" sx={{mb: "3rem"}}>
          <h2 className="text-white">Get your continuous learning to the next level</h2>
        </FormGroup>

        <PricingBoxes />
      </Container>
    </section>

    {/* <section id="faq" className="text-center">
      <h2>FAQ</h2>
      <p className="text-lg">Beschreibung...</p>
    </section> */}

    <footer className="bg-dark">
      <Container maxWidth="lg">
        <Grid container sx={{alignItems: "center"}}>
          <Grid item xs={12} md={4}>
            <Logo color="white" />
            <p className="text-white"><small>Copyright &copy; 2024. All rights reserved.</small></p>
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack direction="row" spacing={6} sx={{justifyContent: "flex-end"}}>
              <small className="text-white">Privacy Policy</small>
              <small className="text-white">Legal Notice</small>
              <small className="text-white">Contact</small>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </footer>
  </div>)
}

export default Landingpage;