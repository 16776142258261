import React, { useState } from 'react';
import { TextField, MenuItem, FormGroup } from '@mui/material';

export const PasswordForm = (props) => {

  const [passwordHasError, setPasswordHasError] = useState(false);
  const [passwordConfirmationHasError, setPasswordConfirmationHasError] = useState(false);

  const checkPasswords = (event) => {
    let password = document.getElementById("password")?.value;
    let passwordConfirmation = document.getElementById("password-confirmation")?.value;
    setPasswordHasError(password.length < 8);
    setPasswordConfirmationHasError(password != passwordConfirmation);
    if (props.onChange !== undefined) { props.onChange() }
  }

  return(<>
    <FormGroup>
      <TextField id="password" type="password" name="password" error={passwordHasError} required helperText="Min. 8 Zeichen notwendig." label="Passwort" variant="outlined" onChange={checkPasswords} />
    </FormGroup>

    <FormGroup>
      <TextField id="password-confirmation" type="password" name="password_confirmation" error={passwordConfirmationHasError} required helperText="Passwörter müssen übereinstimmen." label="Passwort wiederholen" variant="outlined" onChange={checkPasswords} />
    </FormGroup>
  </>);
}

export const ItemSelector = (props) => {
  
  return (
    <TextField
      name={props.name} label={props.label} defaultValue={props.defaultValue}
      select
      variant="outlined"
      onChange={props.onChange}
      required={props.required}
    >
      {props.items?.map(item => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
}