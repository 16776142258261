import React from 'react';
import { AppBar, Container, Chip, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Logo from '../Shared/Logo';
import { ViewProps } from '../../types/View';

const InfoLayout = (props: ViewProps) => {
  return <>
    <AppBar position="relative" sx={{ alignItems: 'center', padding: '3rem 0 0'}}>
      <Logo color="primary" to="/" />
    </AppBar>
    <Container 
      maxWidth="md"
      sx={{marginBottom: '5rem', padding: props.user ? "1rem" : "3rem 1rem"}}>
        
      { props.user && <div className='text-center' style={{marginBottom: "3rem"}}>
        <Chip className='bg-white' label={<Stack direction="row" gap={1}>
          <span>Confirmed as <strong>{ props.user.email }</strong></span>
          <NavLink to="/login"><strong>Change</strong></NavLink>
        </Stack>} />
      </div> }

      {props.children}
    </Container>        
  </>;
}

export default InfoLayout;