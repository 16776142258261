import React from 'react';
import { Paper, List, ListItem, ListItemIcon, ListItemText, FormGroup, Stack } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { NavLink } from "react-router-dom";
import { OnboardingStep } from '../../types/Onboarding';
import TourIcon from '@mui/icons-material/Tour';

type OnboardingStepsProps = {
  steps: Array<OnboardingStep>
}

const OnboardingSteps = (props: OnboardingStepsProps) => {

  return props.steps !== undefined ?
    <Paper className="bg-dark">
      <FormGroup>
        <Stack direction="row" spacing={1}>
          <TourIcon className="text-white" />
          <h3 className='text-white'>Lessonice Einführungs-Tour</h3>
        </Stack>
      </FormGroup>
      
      <List>
        { props.steps.map(step => {
          return <ListItem key={step.todo}>
            <ListItemIcon>{ step.done ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> }</ListItemIcon>
            <ListItemText>{ step.done ? <span className="text-white" style={{opacity: 0.6}}>{step.todo}</span> : <NavLink to={step.link} className="text-white">{step.todo}</NavLink> }</ListItemText>
          </ListItem>
        }) }
      </List>
    </Paper> : <></>;
}

export default OnboardingSteps;