import React, { useState, useEffect } from 'react';
import { Card, Button, Container, FormGroup, Stack, TextField } from '@mui/material';
import Api from '../../network/Api';
import Invitation from '../../types/Invitation';
import { TeamSelectionProps } from './Selection';
import FormUtils from "../../utils/FormUtils";
import OrDivider from '../Shared/OrDivider';
import JoinableList from './JoinableList';
import Team from '../../types/Team';

const NewTeam = (props: TeamSelectionProps) => {
  
  const [invitations, setInvitations] = useState<Array<Invitation> | undefined>();
  const [joinable, setJoinable] = useState<Array<Team> | undefined>();

  useEffect(() => {
    Api.get(`/teams/new`).then((result: TeamSelectionProps) => {
      setInvitations(result.invitations);
      setJoinable(result.joinable);
    });
  }, []);

  return <Container component={Card} maxWidth="sm">
    <h2>Create a new Lessonice team or company</h2>
    <p style={{margin: "1rem 0"}}>Lessonice gives your team a place to exchange lessons learned and generate valuable mitigation actions to prevent those mistakes in the future.</p>
  
    <form method="post" action="/teams" onSubmit={(e) => FormUtils.onFormSubmit(e)}>
      <FormGroup>
        <TextField 
          name="name"
          label='Team name'
          variant='outlined'
          required />
      </FormGroup>

      <FormGroup>
        <Button type="submit" variant="outlined" color="primary">Create Team</Button>
      </FormGroup>

      <FormGroup>
        <p><small>By continuing, you're agreeing to our Terms of Service and Privacy Policy.</small></p>
      </FormGroup>

    </form>

    { ((invitations && invitations.length > 0) || (joinable && joinable.length > 0)) && <section className="bg-dark" style={{margin: "2rem -2rem -2rem", padding: "2rem"}}>
      <div className="bg-dark" style={{ width: 80, height: 80, margin: '-4rem auto -1rem', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 40}}>
        <OrDivider color="white" />
      </div>
     
      <Stack gap={2}>
        <h3 className="text-white">Accept an invitation</h3>
        <JoinableList user={props.user} invitations={invitations} joinable={joinable} />
      </Stack>
    </section> }
  </Container>;
};

export default NewTeam;