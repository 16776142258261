import React, { useState, useEffect } from "react";
import { Card } from '@mui/material';
import Api from "../../network/Api";

const Ranking = () => {

  const [ranking, setRanking] = useState();

  useEffect(() => {
    Api.get(`/lessons`).then(setRanking);
  }, []);

  return (
    <Card className="bg-white">
      <h3>Die wichtigste Lesson Learned</h3>
      { ranking && <>
        <p>{ ranking.summary }</p>
      </> }
      Details (Top 5 Team wide)
      {/*<h2>Unternehmensweite Lessons Learned:</h2>*/}
      {/*{ ranking && <>*/}
      {/*  <ol>{ ranking.lessons_learned.slice(0, 10).map(item => <li>{item}</li>) }</ol>*/}
      {/*</> }*/}
    </Card>
  );
}

export default Ranking;