import React, { useState, useEffect } from "react";
import { Paper, Stack } from '@mui/material';
import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent } from '@mui/lab';
import LessonIcon from '@mui/icons-material/School';
import HistoryIcon from '@mui/icons-material/History';
import Api from "../../network/Api";
import dayjs from "dayjs";

const Actions = () => {

  const [history, setHistory] = useState();

  useEffect(() => {
    Api.get(`/timeline`).then(setHistory);
  }, []);

  const icon = (action_type) => {
    switch(action_type) {
      case 'lesson':
        return <LessonIcon />
      default:
        return <HistoryIcon />
    }
  }

  const content = (item) => {
    switch(item.action_type) {
      case 'lesson':
        return <small><strong>{item.user?.display_name}</strong> hat eine neue Lessons Learned hinzugefügt.</small>
      default: 
        return <small></small>
    }
  }

  return <>{ history?.length > 0 && <Paper>
    <Stack direction="row" spacing={1}>
      <HistoryIcon />
      <h3>Verlauf</h3>
    </Stack>

      <Timeline position="right">{ history?.map(item => 
        <TimelineItem key={item.id}>
          <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right">
            <small className="text-secondary">{dayjs.unix(item.timestamp).format("ll")}</small>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot>{icon(item.action_type)}</TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ m: 'auto 0', flex: 3 }}>{content(item)}</TimelineContent>
        </TimelineItem>
      ) }
      </Timeline>
    </Paper> }
  </>;
}

export default Actions;