import React, { useState, useEffect } from 'react';
import { Button, Card, Paper, Grid, FormGroup, Stack, List, ListItem, ListItemText, IconButton } from '@mui/material';
import Api from '../../network/Api';
import { NavLink } from 'react-router-dom';
import User from '../../types/User';
import Team from '../../types/Team';
import Invitation from '../../types/Invitation';
import JoinableList from './JoinableList';
import TeamItem from './ListItem';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export type TeamSelectionProps = {
  user: User,
  teams?: Array<Team>,
  invitations?: Array<Invitation>,
  joinable?: Array<Team>
}

const TeamSelection = (props: TeamSelectionProps) => {
  
  const [teams, setTeams] = useState<Array<Team> | undefined>();
  const [joinable, setJoinable] = useState<Array<Team> | undefined>();
  const [invitations, setInvitations] = useState<Array<Invitation> | undefined>();

  useEffect(() => {
    Api.get(`/teams`).then((result: TeamSelectionProps) => {
      setTeams(result.teams);
      setInvitations(result.invitations);
      setJoinable(result.joinable);
    });
  }, []);

  const setTeam = (teamId) => {
    Api.put(`/user/team`, { team_id: teamId }).then(result => window.location.replace(result.url));
  }

  const CreateBox = () => {
    return <Card className='bg-dark' elevation={0}>
      <Grid container spacing={2} sx={{alignItems: "center"}}>
        <Grid item xs={12} md={8}>
          <h3 className="text-white text-center">Want to use Lessonice in a different team?</h3>
        </Grid>
        <Grid item xs={12} md={4} className='text-center'>
          <Button component={NavLink} 
                  to="/teams/new" 
                  variant='outlined' 
                  color='primary' 
                  size="small">Add another team</Button>
        </Grid>
      </Grid>
    </Card>
  }

  return <Stack gap={4}>
    { teams && teams.length > 0 ? <>
      <div className="text-center">
        <h2>Welcome back{ props.user.display_name && `, ${props.user.display_name}` }!</h2>
        <p>Choose a team or create a new one.</p>
      </div>
      
      <Paper sx={{padding: "0 !important"}}>
        <List>
          <ListItem><ListItemText>Teams for <strong>{ props.user.email }</strong></ListItemText></ListItem>
          {
            teams?.map(team => <ListItem>
              <TeamItem team={team} />
              <IconButton sx={{ml: 'auto'}} onClick={() => setTeam(team.id)}><ArrowForwardIcon /></IconButton>
            </ListItem>)
          }
        </List>
      </Paper>

      { CreateBox() }

      { ((invitations && invitations.length > 0) || (joinable && joinable.length > 0)) && <Stack gap={2} sx={{mt: "1rem"}}>
        <h3>Pending Invitations</h3>
        <JoinableList user={props.user} invitations={invitations} joinable={joinable} />
      </Stack> }
    </> : <>
      <FormGroup sx={{textAlign: 'center'}}>
        <h2>Welcome to Lessonice</h2>
        <p>You can join any of the teams below, or add another one.</p>
      </FormGroup>

      { invitations && invitations.length > 0 && <JoinableList user={props.user} invitations={invitations} joinable={joinable} /> }
      { CreateBox() }
    </> }
  </Stack>;
};

export default TeamSelection;