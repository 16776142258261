import React, { useState, useEffect } from 'react';
import { FormGroup, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Api from '../../network/Api';
import User from '../../types/User';
import Team from '../../types/Team';
import FormUtils from '../../utils/FormUtils';
import OnboardingLayout from "./Layout";

export type OnboardingProps = {
  user: User
}

type TeamOnboardingParams = {
  name?: string,
  domain_whitelist: "true" | "false",
  industry?: string,
  description?: string,
  is_ready: "true" | "false"
}

const TeamOnboarding = (props: OnboardingProps) => {
  
  const [team, setTeam] = useState<Team>();
  const [params, setParams] = useState<TeamOnboardingParams>({ domain_whitelist: "true", is_ready: "true" });
  const [isLoading, shouldLoad] = useState<boolean>(false);

  useEffect(() => {
    Api.get(`/team`).then(setTeam);
  }, []);

  return <OnboardingLayout title="Your team is almost ready"><>
    { team && <form method="put" action="/team" onSubmit={(e) => { FormUtils.onFormSubmit(e); shouldLoad(true); }}>
      <FormGroup><p>Please add a few more details about your team. You can skip this step if you want and come back later.</p></FormGroup>
      <FormGroup><TextField name="name" label="Name des Teams" defaultValue={team.name} required variant="outlined" onChange={(e) => { setParams({...params, name: e.target.value}) }} /></FormGroup>
      <FormGroup><TextField name="industry" label="Industrie/Branche" defaultValue={team.industry} variant="outlined" onChange={(e) => { setParams({...params, name: e.target.value}) }} /></FormGroup>
      <FormGroup><TextField name="description" label="Beschreibung des primären Aufgabenbereichs" defaultValue={team?.description} variant="outlined" onChange={(e) => { setParams({...params, name: e.target.value}) }} /></FormGroup>
      <FormGroup><FormControlLabel control={<Checkbox onChange={(e) => { setParams({...params, domain_whitelist: e.target.value}) }} defaultChecked={params.domain_whitelist == "true"} />} label={`Let anyone with an @${props.user.domain} email join this team.`} /></FormGroup>
      <input type="hidden" name="domain_whitelist" value={params.domain_whitelist} />
      <input type="hidden" name="is_ready" value={params.is_ready} />
      <LoadingButton loading={isLoading} variant="outlined" type="submit">Save</LoadingButton>
    </form> }
  </></OnboardingLayout>
};

export default TeamOnboarding;