import React from 'react';
import { TeamSelectionProps } from './Selection';
import { Paper, List, ListItem, ListItemText, Stack, Button } from '@mui/material';
import TeamItem from './ListItem';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Api from '../../network/Api';

const JoinableList = (props: TeamSelectionProps) => {

  const join = (teamId: string) => {
    Api.post(`/teams/${teamId}/users`, {}).then(result => window.location.replace(result.url));
  }

  return <Paper sx={{padding: "0 !important"}}><List>
    <ListItem><ListItemText>Invitations for <strong>{ props.user.email }</strong></ListItemText></ListItem>
    {
      props.invitations && props.invitations.map(invitation => <ListItem><TeamItem team={invitation.team} /><a href="#" onClick={() => {}}>Test</a></ListItem>)
    }
    {
      props.joinable && props.joinable.map(team => <ListItem>
        <ListItemText>
          <small className="text-secondary"><MailOutlineIcon fontSize="small" sx={{verticalAlign: "-0.3rem"}} /> <strong>Anyone with @{ props.user.domain } can join</strong></small>
          <Stack direction="row" sx={{mt: '0.5rem'}}>
            <TeamItem team={team} />
            <Button variant="outlined" color="primary" onClick={() => join(team.id)} sx={{ml: 'auto'}}>Join</Button>
          </Stack>
        </ListItemText>
      </ListItem>)
    }
  </List></Paper>
}

export default JoinableList;