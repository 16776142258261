import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Button, LinearProgress, Divider } from '@mui/material';
import DropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UserMenu from './UserMenu';
import Avatar from './Avatar';
import Logo from './Logo';
import LevelProgress from './LevelProgress';
import { LayoutProps } from '../../types/View';

const UserNav = (props: LayoutProps) => {

  const [showsUserMenu, setShowsUserMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const toggleMenu = (event) => {
    setShowsUserMenu(!showsUserMenu);
    setAnchorEl(event.currentTarget);
  }

  return (
    <AppBar component="nav" position="relative">
      <Toolbar sx={{gap: '2rem', minHeight: "80px !important"}}>
        <Logo color="primary" />

        <span style={{marginLeft: 'auto'}}></span>

        <LevelProgress user={props.user!} />

        <Button size="small"
                variant="text"
                onClick={toggleMenu}>
          <Avatar size={30} /><DropDownIcon />
        </Button>
        <UserMenu {...props}
                  anchorEl={anchorEl} 
                  open={showsUserMenu}
                  onClose={toggleMenu} />
      </Toolbar>
    </AppBar>
  );
}

export default UserNav;