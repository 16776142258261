import React, { useState } from 'react';
import { NavLink, useParams } from "react-router-dom";
import FormUtils from "../../utils/FormUtils";
import { FormGroup, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PasswordForm } from '../Shared/FormItems';

const ResetPwdForm = (props) => {

  const { userId, authId } = useParams();
  const [isLoading, shouldLoad] = useState(false);
  
  return (
    <form method="put" action={`/users/${userId}/authentication/${authId}`} onSubmit={(e) => { FormUtils.onFormSubmit(e); shouldLoad(true); }}>
      <FormGroup>
        <p>Hier kannst du ein neues Passwort setzen.</p>
      </FormGroup>

      <PasswordForm />

      <FormGroup>
        <LoadingButton variant="contained" type="submit" loading={isLoading}>Passwort zurücksetzen</LoadingButton>
      </FormGroup>

      <FormGroup>
        <p className='text-center'><NavLink to="/login">Zurück zur Anmeldung</NavLink></p>
      </FormGroup>
    </form>
  );
};

export default ResetPwdForm;