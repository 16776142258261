import React, { useState, useEffect } from "react";
import { Paper, Autocomplete, Chip, TextField, FormGroup, Stack } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import FormUtils from "../../utils/FormUtils";
import Api from "../../network/Api";
import { ItemSelector } from "../Shared/FormItems";

const Ask = () => {

  const [roles, setRoles] = useState([]);
  const [labels, setLabels] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState();
  const [isLoading, shouldLoad] = useState(false);
  const [actions, setActions] = useState();

  useEffect(() => {
    Api.get(`/labels`).then(setLabels);
    Api.get(`/roles`).then(setRoles);
  }, []);

  useEffect(() => {
    shouldLoad(false);
  }, [actions]);

  return (
    <Paper>
      <FormGroup>
       <h3>Abfrage starten</h3>
      </FormGroup>
      
      <form method="post" action="/lessons/ask" onSubmit={(e) => { FormUtils.onFormSubmit(e, (result) => setActions(result.actions)); }}>
        <FormGroup>
          <Autocomplete
            multiple
            options={labels}
            disableClearable
            getOptionLabel={(option) => option.name}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="filled" 
                      color="primary"
                      label={option.name} 
                      {...getTagProps({ index })} />
              ))
            }
            onChange={(e, value) => setSelectedLabels(value.map(label => label.id))}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Themen"
                placeholder="Themen"
              />
            )}
          />
          <input type="hidden" name="label_ids" value={selectedLabels} />
        </FormGroup>

        <FormGroup>
          <ItemSelector 
            name="role_id"
            label="Rolle"
            items={roles.map(role => ({ label: role.name, value: role.id }))}
             />
        </FormGroup>

        <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'flex-end', gap: 2}}>
          <LoadingButton variant="outlined" type="submit" onClick={() => shouldLoad(true)} isLoading={isLoading}>Abfrage starten</LoadingButton>
        </Stack>

        { actions && <>
          <h3>Ergebnis</h3>
          <ul>
            { actions.map(action => <li key={action}>{action}</li>) }
          </ul>
        </> }

        <ul>
          <li>Checkboxen -> übernehmen / nicht übernehmen</li>
          <li>Weitere ergebnisse laden</li>
          <li>Ergebnis speichern -> Name zuordnen</li>
        </ul>
      </form>
    </Paper>
  );
}

export default Ask;