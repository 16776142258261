import React from 'react';
import { Divider, Button, FormGroup, getTextFieldUtilityClass } from '@mui/material';
import { PasswordForm } from '../Shared/FormItems';
import FormUtils from "../../utils/FormUtils";

class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.didReceiveUser = this.didReceiveUser.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = { user: this.props.user, product: undefined, didChangeUser: false, didChangePassword: false };
  }

  didReceiveUser(user) {
    this.props.onChange(user);
    this.setState({ didChangeUser: false, didChangePassword: false });
  }

  onChange() {
    this.setState({ didChangeUser: true});
  }
  
  render() {
    return <>
      <form method="put" action="/user" autoComplete="off" onSubmit={(e) => { FormUtils.onFormSubmit(e, result => {
        if (result.url) {
          window.location.replace(result.url);
        } else {
          this.didReceiveUser(result);
        }
      })}}>
        <FormGroup sx={{mb: "1rem !important;"}}>
          <h3>Benutzerdaten</h3>
        </FormGroup>

        { this.state.user !== undefined && <>
          <FormGroup row={true}>
            <TextField name="display_name" required label="Display Name" defaultValue={this.state.user.display_name} variant="outlined" onChange={this.onChange} />
          </FormGroup>

          <FormGroup>
            <TextField name="email" type="email" required label="Email" variant="outlined" defaultValue={this.state.user.email} onChange={this.onChange} />
          </FormGroup>

          <FormGroup row>
            <Button sx={{mr: "auto"}} variant="outlined" color="primary" type="submit" disabled={!this.state.didChangeUser}>Speichern</Button>
          </FormGroup>
        </> }
      </form>
    
      <Divider />

      <form method="put" action="/user" autoComplete="off" onSubmit={(e) => { FormUtils.onFormSubmit(e, this.didReceiveUser) }}>
        <FormGroup sx={{mb: "1rem !important;"}}>
          <h3>Passwort ändern</h3>
        </FormGroup>

        <PasswordForm onChange={() => this.setState({didChangePassword: true})} />

        <FormGroup row>
          <Button sx={{mr: "auto"}} variant="outlined" color="primary" type="submit" disabled={!this.state.didChangePassword}>Ändern</Button>
        </FormGroup>
      </form>
    </>;
  }
};

export default Settings;