import React from 'react';
import { Card, Container, Grid } from '@mui/material';
import { ViewProps } from '../../types/View';

type OnboardingLayoutProps = {
  title: string
}

const OnboardingLayout = (props: OnboardingLayoutProps & ViewProps) => {

  return <Container component={Card} maxWidth="sm">
    <Grid container sx={{width: "auto", margin: "-2rem"}}>
      <Grid item xs={12} sm={4} className="bg-dark" sx={{padding: "1rem 2rem", alignContent: "center"}}>
        <h3 className='text-white'>{props.title}</h3>
      </Grid>
      <Grid item xs={12} sm={8} sx={{padding: "2rem"}}>
        {props.children}
      </Grid>
    </Grid>
  </Container>
};

export default OnboardingLayout;