import React from 'react';
import { NavLink } from "react-router-dom";
import { Grid, Card, Chip, Stack, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

type ProductProps = {
  name: string,
  label: string,
  badge?: string,
  priceInfo: string,
  price: number,
  interval: "month",
  cta: string,
  features: Array<string>,
  url: string
}

const PricingBoxes = (props) => {

  return <>
  <Grid container spacing={4} sx={{mb: "1rem"}}>
    <Grid item xs={12} md={4}>
      <PricingBox name='Free'
                  label='All our premium features. For free.'
                  priceInfo='No credit card required.'
                  price={0}
                  interval='month'
                  cta='Start now'
                  features={["Store unlimited lessons learned", "Up to 5 active users per month", "Up to 1,000 requests"]}
                  url="/login" />
    </Grid>

    <Grid item xs={12} md={4}>
      <PricingBox name='Premium'
                  badge='Bestseller'
                  label='The best choice for high performing teams.'
                  priceInfo='Starting at'
                  price={29.99}
                  interval='month'
                  cta='Get started'
                  features={["Priority service", "Up to 25 active users per month", "Unlimited requests"]}
                  url="/login" />
    </Grid>

    <Grid item xs={12} md={4}>
      <PricingBox name='Enterprise'
                  label='Enterprise-level power. Talk to us to build your plan.'
                  priceInfo='Starting at'
                  price={2000}
                  interval='month'
                  cta='Talk to sales'
                  features={["Customizable plans", "Enterprise controls", "Access enterprise resources"]}
                  url="/login" />
    </Grid>
  </Grid>

  <p className="text-white">Prices do not include applicable taxes.</p>
</>
};

const PricingBox = (props: ProductProps) => {
  return <Card sx={{outline: "4px solid rgba(255,255,255,0.2)"}}>
    <Stack spacing={3}>
      <Stack spacing={1} direction="row" sx={{alignItems: "center"}}>
        <h2>{props.name}</h2>
        {props.badge && <Chip label={props.badge} variant="outlined" color="primary" />}
      </Stack>
      <h3>{props.label}</h3>
      <p>{props.priceInfo}</p>
      <h2><span style={{fontSize: '2.25rem'}}>${props.price}</span> <small>/{props.interval}</small></h2>
      <Button variant="outlined" 
              color="primary" 
              endIcon={<ArrowRightAltIcon />}
              component={NavLink}
              to={props.url}>{props.cta}</Button>
      {props.features.map(feature => <p><CheckIcon /> {feature}</p>)}
    </Stack>
  </Card>
}

export default PricingBoxes;