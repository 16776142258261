import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Backdrop, Container, Grid, Stack, Fab, Paper } from '@mui/material';
import OnboardingSteps from "../Onboarding/OnboardingSteps";
import { OnboardingStep } from "../../types/Onboarding";
import Timeline from "../Timeline/Actions";
import NewLesson from "./New";
import Ask from "./Ask";
import Api from "../../network/Api";
import TourElement from "../Onboarding/TourElement";

import Toolbox from "../Shared/Toolbox";
import Settings from "../User/Settings";
import TeamSettings from "../Teams/Settings";

import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

const LessonsLearned = () => {

  const [steps, setSteps] = useState<Array<OnboardingStep>>([]);
  const [currentStep, setCurrentStep] = useState<string | undefined>();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    Api.get(`/onboarding`).then(result => {
      setSteps(result.todos);
      setCurrentStep(result.current_tour_step);
    });
  }, []);

  const nextTourStep = () => {
    let nextStep = steps.filter(step => step.tour_reference && step.tour_reference != currentStep)[0];
    setCurrentStep(nextStep.tour_reference);
  }

  const endTour = () => {
    setCurrentStep(undefined);
    Api.delete("/onboarding")
  }

  return <>
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        
        <Grid item xs={12} md={4}>
          <TourElement title="Step 1 of 2"
                       info="Add any lessons learned to grow your teams' experience database."
                       isActive={"new-lesson" === currentStep} 
                       onNext={nextTourStep}
                       onComplete={endTour}><NewLesson /></TourElement>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper>
            <Stack direction="row" sx={{placeContent: 'space-between'}}>
              <h3>Lessons Learned</h3>
              <Fab color="primary" disabled><TipsAndUpdatesIcon /></Fab>
            </Stack>
            <h2>1234</h2>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper>
            <h3>Aktivität</h3>
            <div className='area-chart'></div>
          </Paper>
        </Grid>

        <Grid item md={8}>
          <Stack spacing={4}>
            <TourElement title="Step 2 of 2"
                         info="Get out some valuable action points based on the experience you have gained."
                         isActive={"ask" === currentStep} 
                         onComplete={endTour}><Ask /></TourElement>

            <Timeline />
          </Stack>
        </Grid>
        <Grid item md={4}>
          <Stack spacing={4}>
            { steps?.filter(step => !step.done)?.length > 0 && <OnboardingSteps steps={steps} /> }            
          </Stack>
        </Grid>
      </Grid>
    </Container>

    { currentStep && <Backdrop open /> }

    { pathname === '/user' && <Toolbox title="Account Settings" open onClose={() => navigate('/app')}><Settings /></Toolbox> }
    { pathname === '/team' && <Toolbox title="Team Settings" open onClose={() => navigate('/app')}><TeamSettings /></Toolbox> }
  </>;
}

export default LessonsLearned;