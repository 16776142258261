import React from 'react';
import { Button } from '@mui/material';
import GoogleIcon from 'images/g-logo.png';

const GoogleSignIn = () => {
  return (
    <Button 
      href="/login/google" 
      variant="outlined" 
      color="secondary"  
      startIcon={<img src={GoogleIcon} width="22px" height="22px" />}>Sign in with Google</Button>
  );
}

export default GoogleSignIn;