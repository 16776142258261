import React from 'react';
import { Stack } from '@mui/material';
import Avatar from '../Shared/Avatar';

const TeamItem = (props) => {
  return <Stack direction="row" gap={2} sx={{alignItems: 'center'}}>
    <Avatar name={props.team.name} />
    <Stack>
      <h3><small>{props.team.name}</small></h3>
      <small className="text-secondary">{props.team.members} members</small>
    </Stack>
  </Stack>
}

export default TeamItem;