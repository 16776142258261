import React, { useRef } from 'react';
import { ViewProps } from "../../types/View";
import { Button, Popover, Box, ButtonGroup } from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

type TourProps = {
  title: string,
  info: string,
  isActive: boolean,
  onNext?: () => void,
  onComplete: () => void
}

const TourElement = (props: ViewProps & TourProps) => {

  const tourRef = useRef();

  return <Box style={{position: 'relative', zIndex: props.isActive ? 2 : 0}}>
    <Box ref={tourRef}>{props.children}</Box>

    { props.isActive && <Popover
      open
      anchorEl={tourRef.current}
      anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
      className="tour-popover"
    >
      <h3 className="text-white"><small>{props.title}</small></h3>
      <p className="text-white">{props.info}</p>

      <ButtonGroup variant="text" 
                   color="secondary"
                   size='small'
                   sx={{marginTop: "2rem"}}>
        <Button onClick={props.onComplete} >Tour beenden</Button>
        { props.onNext && <Button endIcon={<ArrowRightAltIcon fontSize='small' />}
                                  onClick={props.onNext} >Weiter</Button> }
      </ButtonGroup>
      
    </Popover> }
  </Box>
}

export default TourElement;