import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landingpage from "./Pages/Landingpage";
import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import locale from '../locales/de.json';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/de';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

import Layout from "./Layouts/Layout";

import LoginForm from "./Authentication/LoginForm";
import LessonsLearned from "./Lessons/Dashboard";
import VerificationForm from "./Authentication/VerificationForm";
import UserOnboarding from "./Onboarding/UserOnboarding";
import TeamOnboarding from "./Onboarding/TeamOnboarding";
import TeamSelection from "./Teams/Selection";
import NewTeam from "./Teams/New";
import User from "../types/User";
import { FlashProps } from "../types/View";
import Team from "../types/Team";

i18n
  .use(initReactI18next)
  .init({
    resources: locale,
    lng: "de"
  });

dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.locale('de');
dayjs.extend(localizedFormat)

type AppProps = {
  user: User,
  team?: Team,
  flash: FlashProps,
}

class App extends React.Component {

  constructor(props: AppProps) {
    super(props);
    this.state = { user: props.user, team: props.team, flash: props.flash };
  }

  updateUser(user: User) {
    this.setState({ user: user });
  }

  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Landingpage />} />
          {/*<Route path="/terms" exact element={this.renderLayout({ info: true, view: <Terms /> })} />*/}
          {/*<Route path="/privacy" exact element={this.renderLayout({ info: true, view: <Privacy />})} />*/}
          <Route path="/teams" element={<Layout variant="info" {...this.state}><TeamSelection user={this.state.user} /></Layout>} />
          <Route path="/teams/new" element={<Layout variant="info" {...this.state}><NewTeam user={this.state.user} /></Layout>} />
          
          <Route path="/onboarding/user" element={<Layout variant="info" {...this.state}><UserOnboarding user={this.state.user} /></Layout>} />
          <Route path="/onboarding/team" element={<Layout variant="info" {...this.state}><TeamOnboarding user={this.state.user} /></Layout>} />

          <Route path="/login" element={<Layout variant="info" {...this.state}><LoginForm /></Layout>} />
          <Route path="/verifications/:verificationId" element={<Layout variant="info" {...this.state}><VerificationForm /></Layout>} />
          {/* <Route path="/login/forgot" exact element={this.renderLayout({ info: true, view: <ForgotPwdForm /> })} /> */}
          {/* <Route path="/eusers/:userId/authentication/:authId/edit" exact element={this.renderLayout({ info: true, view: <ResetPwdForm /> })} /> */}

          <Route path="/app" element={<Layout variant="application" {...this.state}><LessonsLearned /></Layout>} />
          <Route path="/team" element={<Layout variant="application" {...this.state}><LessonsLearned /></Layout>} />
          <Route path="/user" element={<Layout variant="application" {...this.state}><LessonsLearned /></Layout>} />

        </Routes>
      </Router>
    );
  }
}

export default App;