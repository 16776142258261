import React from 'react';
import { Container, Divider, Grid, Card, Button, FormGroup, TextField, Alert, Skeleton } from '@mui/material';
import FormUtils from "../../utils/FormUtils";
import Api from '../../network/Api';

class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.deleteTeam = this.deleteTeam.bind(this);
    this.state = {};
  }

  componentDidMount() {
    Api.get("/team/roles").then(result => this.setState({ roles: result }));
    Api.get("/team/users").then(result => this.setState({ users: result }));
  }

  deleteTeam() {
    Api.delete("/team").then(result => window.location.replace(result.url));
  }
  
  render() {
    return <>
      <form method="put" action="/team" autoComplete="off" onSubmit={(e) => { FormUtils.onFormSubmit(e, result => {
        if (result.url) {
          window.location.replace(result.url);
        } else {
          this.setState({ team: result });
        }
      })}}>
        <FormGroup sx={{mb: "1rem !important;"}}>
          <h3>Stammdaten</h3>
        </FormGroup>

        { this.state.team !== undefined && <>
          <FormGroup row={true}>
            <TextField name="name" required label="Team" defaultValue={this.state.team.name} variant="outlined" onChange={this.onChange} />
          </FormGroup>

          <FormGroup row={true}>
            <TextField name="industry" label="Industrie/Branche" defaultValue={this.state.team.industry} variant="outlined" onChange={this.onChange} />
          </FormGroup>

          <FormGroup row={true}>
            <TextField name="description" label="Beschreibung des primären Aufgabenbereichs" defaultValue={this.state.team.description} variant="outlined" onChange={this.onChange} />
          </FormGroup>

          <FormGroup row>
            <Button sx={{mr: "auto"}} variant="outlined" color="primary" type="submit">Speichern</Button>
          </FormGroup>
        </> }
      </form>
    
      <Divider />

      <form method="put" action="/user" autoComplete="off" onSubmit={(e) => { FormUtils.onFormSubmit(e, this.didReceiveUser) }}>
        <FormGroup sx={{mb: "1rem !important;"}}>
          <h3>Rollen verwalten</h3>
        </FormGroup>

        { this.state.roles?.map(role => <p><strong>{role.name}</strong><br />{role.description}</p>) }
      </form>

      <Divider />

      <form method="put" action="/user" autoComplete="off" onSubmit={(e) => { FormUtils.onFormSubmit(e, this.didReceiveUser) }}>
        <FormGroup sx={{mb: "1rem !important;"}}>
          <h3>Benutzer verwalten</h3>
        </FormGroup>

        { this.state.users?.map(user => <p><strong>{user.display_name}</strong><br />{user.email}</p>) }
      </form>

      <Divider />

      <Button variant="text" color="error" onClick={() => {}}>Leave team</Button>
      <Button variant="text" color="error" onClick={this.deleteTeam}>Delete team</Button>
    </>;
  }
};

export default Settings;