import React from 'react';
import { Drawer, FormGroup, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type ToolboxProps = {
  children: JSX.Element,
  title: string,
  open: boolean,
  onClose: () => void
}

const Toolbox = (props: ToolboxProps) => {
  return (
    <Drawer
      variant="temporary"
      anchor='right'
      open={props.open}
    >
      <FormGroup>
        <Stack spacing={2} direction="row" sx={{alignItems: 'center'}}>
          <h3>{props.title}</h3>
          <IconButton onClick={props.onClose} sx={{marginLeft: 'auto !important'}}><CloseIcon /></IconButton>
        </Stack>
      </FormGroup>
      {props.children}
    </Drawer>
  );
}

export default Toolbox;